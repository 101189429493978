import React, { Fragment, useEffect, useState, useMemo, useRef } from 'react';
import { branch, mapProps, withProps } from "recompose";
import { ContextFolder, ContextLink, CancellableSimpleLink } from '../../containers/link';
import classNames from 'classnames';
import styled from 'styled-components'
import MenuCtnr from '../../common/v5/menuCtnr';
import { useShowAndHide } from "../../hooks/toggle";
import {ErrandListCtnr} from '../../containers/chat.js';
import ToggleSideBar from '../../components/v5/ToggleSideBar';
import { Filters } from '../../components/v5/TopFilter';
import { SidebarIconItemBasic, SideBarDropdownWrapperWithTooltip } from '../../components/v5/SidebarSettings';
import { HotkeyLabel } from '../../reactcomponents/Hotkeys';
import {
	composeWithDisplayName
	, withUnmountWhenHidden
} from "../../reactcomponents/hocs";
import ErrandCtnr from './errandCtnrs';
import {
	SidebarSettingsCtnr,
	WorkflowInnerContainerCtnr,
	IconizeSideBarMenuCtnr
} from './workflowCtnr';
import Feature from '../../components/feature';
import { FolderCarousel } from '../../components/v5/Widget';
import {
	getExtQueueType
	, isValidTelavoxUrl
} from '../../common/v5/helpers';
import {
	CTX_REVIEW,
	MY_ERRANDS,
	NEW_ERRANDS,
	VIP_ERRANDS,
	REVIEW_ERRANDS,
	SEARCH_ERRANDS,
	PREF_LIST_VIEW,
	PREF_CONVERSATION_VIEW,
	UI_SHOW_MANUAL_ERRAND,
	UI_SHOW_OLD_CHAT,
	SEARCH_NEW_ERRANDS,
	SEARCH_MY_ERRANDS,
	SEARCH_CLOSE_ERRANDS,
	MM_WORKFLOW,
	CENTION_LOGO,
	CENTION_LOGO_MINI,
	CTX_POSTPONED,
	POSTPONED_ERRANDS
} from '../../common/v5/constants';
import 'jquery-ui-bundle';
import "jquery";
import 'bootstrap';
import { I } from '../../common/v5/config.js';
import { WFP_OLD_CHAT } from '../../common/v5/constants';
import { IsContextSearch } from '../../common/v5/utils';
import {
	headerSlimHeight,
	appHeaderHeight,
	handlingBarHeight
} from '../../styles/_variables'
import HeaderSearch from '../../components/v5/HeaderSearch';
import { AgentNotification } from '../../common/v5/header';
import { isMobile } from '../../redux/util';
import { SkeletonLoader } from '../../components/v5/SkeletonFrames';

const FolderWithDataQA = ({ folderKey, ...props }) => <ContextFolder folderKey={folderKey} {...props} data-qa-id={"QA_folder_" + folderKey} />;

const Folder = ({ current, ...props }) => <FolderWithDataQA {...props} active={current == props.id} />;

const FixFolderBase = ({ current, icon, ...props }) => <FolderWithDataQA {...props} active={current == props.value} />;

const FixFolder = props => <FixFolderBase {...props} />;

const searchValueProps = {
	[SEARCH_ERRANDS]: {
		icon: "icon-search-results"
		, folderKey: "searchErrands"
		, text: I("Search results")
	}
	, [SEARCH_NEW_ERRANDS]: {
		hotkey: "1"
		, icon: "icon-errands"
		, id: 3
		, folderKey: "searchAllErrands"
		, text: I("All errands")
	}
	, [SEARCH_MY_ERRANDS]: {
		hotkey: "2"
		, icon: "icon-errands"
		, id: 4
		, folderKey: "searchMyErrands"
		, text: I("Search results")
	}
	, [SEARCH_CLOSE_ERRANDS]: {
		hotkey: "3"
		, icon: "icon-closed-errands"
		, id: 5
		, folderKey: "SearchCloseErrands"
		, text: I("Closed errands")
	}
};

const FixSearchFolderBaseBase = ({ active, icon, ...props }) => <FolderWithDataQA active={active} icon={icon} {...props} />;

const withUseIcon = mapProps(({ icon, useIcon, ...props }) => {
	const updatedProps = { withCount: !useIcon, ...props }
		;
	if (useIcon) {
		updatedProps.icon = icon;
	}
	return updatedProps;
});

const withOrWithoutHotkeyLabel = branch(
	({ hotkey }) => hotkey !== "undefined"
	, mapProps(({
		folderKey
		, hotkey
		, id
		, text
		, ...props
	}) => {
		return {
			folderKey
			, id
			, text: <HotkeyLabel
				hotkey={hotkey}
				id={folderKey}
				text={text}
			/>
			, ...props
		};
	})
);

const SidebarIcon = ({ active, ...props }) => <SidebarIconItemBasic className={classNames("collapse-menu search-result", { active })} {...props} />;

const withSidebarIcon = Component => ({ active, count, value, ...props }) => (
	<SidebarIcon
		active={active}
		id={value}
		data-qa-id={"search-result-" + value}
		title={value + " (" + count + ")"}
	>
		<Component active={active} count={count} value={value} {...props} />
	</SidebarIcon>
);

const FixSearchFolder = composeWithDisplayName(
	"FixSearchFolder"
	, withProps(({ collapseSideBar, value }) => ({
		useIcon: !!collapseSideBar
		, ...searchValueProps[value]
	}))
	, withUseIcon
	, withOrWithoutHotkeyLabel
	, mapProps(({ current, ...props }) => ({ active: current == props.value, ...props }))
	, branch(
		({ collapseSideBar }) => !!collapseSideBar
		, withSidebarIcon
	)
)(FixSearchFolderBaseBase);

const ReviewBase = props => <FixFolderBase {...props} folderKey={CTX_REVIEW} text={I("Review")} value={CTX_REVIEW} />

const ReviewFolder = withUnmountWhenHidden(ReviewBase);

const PostponedBase = props => <FixFolderBase {...props} folderKey={CTX_POSTPONED} text={I("Postponed")} value={CTX_POSTPONED} />

const PostponedFolder = withUnmountWhenHidden(PostponedBase);

const VIPFolderBase = props => <FixFolder {...props} folderKey={VIP_ERRANDS} text={I("VIP errands")} value={VIP_ERRANDS} />;

const VIPFolder = withUnmountWhenHidden(VIPFolderBase);

const SearchFolder = props => <FixSearchFolder value={SEARCH_ERRANDS} {...props} />;

const IconBack = () => <i className="icon-return" />;

const withBackToSearchSidebarIcon = Component => ({ text, ...props }) => (
	<SidebarIcon
		id="backToSearchErrand"
		data-qa-id="backToSearchErrand"
		title={text}
	>
		<Component text={<IconBack />} {...props} />
	</SidebarIcon>
);

const BackToSearchBase = props => (
	<ContextLink data-qa-id="QA_folder_back_searchErrands" {...props} />
);

const BackToSearch = branch(
	({ collapseSideBar }) => !!collapseSideBar
	, withBackToSearchSidebarIcon
)(BackToSearchBase);

const SearchAndBackToBackBase = ({ backToSearch, collapseSideBar, search }) => {
	let first, second;
	if (collapseSideBar) {
		first = backToSearch;
		second = search;
	} else {
		first = search;
		second = backToSearch;
	}
	return (
		<Fragment>
			{first}
			{second}
		</Fragment>
	);
};

const SearchAndBackToBack = withUnmountWhenHidden(SearchAndBackToBackBase);

const AllFolder = (props) => {
	let text = <HotkeyLabel id={"allErrands"} text={I("All errands")} hotkey={"1"} />;
	return <FixFolder {...props} folderKey="allErrands" id={0} value={NEW_ERRANDS} text={text} />;
};

const MyFolder = (props) => {
	let text = <HotkeyLabel id={"myErrands"} text={I("My errands")} hotkey={"2"} />;
	return <FixFolder {...props} folderKey="myErrands" value={MY_ERRANDS} text={text} />;
};

const SearchAllErrand = props => <FixSearchFolder value={SEARCH_NEW_ERRANDS} {...props} />;

const SearchMyErrand = props => <FixSearchFolder value={SEARCH_MY_ERRANDS} {...props} />;

const SearchCloseErrand = props => <FixSearchFolder value={SEARCH_CLOSE_ERRANDS} {...props} />;

const SearchFoldersBase = ({
	allCount
	, closedCount
	, collapseSideBar
	, currentContext
	, myCount
	, onClick
}) => (
	<Fragment>
		<SearchAllErrand
			collapseSideBar={collapseSideBar}
			current={currentContext}
			count={allCount}
			onClick={onClick}
		/>
		<SearchMyErrand
			collapseSideBar={collapseSideBar}
			current={currentContext}
			count={myCount}
			onClick={onClick}
		/>
		<SearchCloseErrand
			collapseSideBar={collapseSideBar}
			current={currentContext}
			count={closedCount}
			onClick={onClick}
		/>
	</Fragment>
);

const withDivWrapper = Component => props => (
	<div><Component {...props} /></div>
);

const SearchFolders = composeWithDisplayName(
	"SearchFolders"
	, withUnmountWhenHidden
	, branch(
		({ collapseSideBar }) => !collapseSideBar
		, withDivWrapper
	)
)(SearchFoldersBase);

// TODO: active prop always true should be remove, when it is false its parent
// is unmount or hidden.
const ErrandsItemBase = ({ active }) => (
	<li className={classNames("collapse-tooltip-title-wrapper", { active })}>
		<a className="tooltip-nav-title">{I("Errands")}</a>
	</li>
);

const Grids = ({ className, children }) => <div className={className}>{children}</div>

const ErrandsItem = withUnmountWhenHidden(ErrandsItemBase);

const ErrandFoldersBase = ({
	all
	, currentContext
	, folders
	, my
	, onFilterByFolderClick
	, review
	, selectedFolder
	, vip
	, postponed
	, gridView
	, onSetGridFolderIndex
	, gridFolderIndex
	, contextChanged
}) => {
	if (process.env.NODE_ENV !== 'production') {
		console.log("currentContext: ", currentContext)
	}
	const customFolders = useMemo(() => {
		let userCustomFolder = [];
		if (currentContext === MY_ERRANDS) {
			folders.forEach((v) => {
				if (v.value !== MY_ERRANDS) {
					userCustomFolder.push(
						<Folder
							key={v.id}
							id={v.id}
							value={v.value}
							text={v.value}
							folderKey={v.id}
							current={selectedFolder}
							onClick={onFilterByFolderClick}
						/>
					);
				}
			});
		}
		return userCustomFolder;
	}, [currentContext, folders, selectedFolder, onFilterByFolderClick]);

	const folderSets = useMemo(() => {
		let grids = [],
			gridIds = [];
		let page = [],
			pageIds = [];
		let count = 0,
			gridsPerPage = 4;
		let folderLength = folders.length;
		let folderSets = [
			{
				page: 0,
				view: (
					<Grids className={"item-wrapper"}>
						{review}
						{postponed}
						{all}
						{my}
						{vip}
					</Grids>
				),
			},
		];

		if (currentContext === MY_ERRANDS) {
			folders.forEach((v) => {
				if (v.value !== MY_ERRANDS) {
					//grid view logic
					count++;
					//push into grid
					grids.push(
						<Folder
							key={v.id}
							id={v.id}
							value={v.value}
							text={v.value}
							folderKey={v.id}
							current={selectedFolder}
							onClick={onFilterByFolderClick}
						/>
					);
					gridIds.push(v.id);
					if (count === gridsPerPage) {
						//push into page, and deduct length
						folderLength -= gridsPerPage;
						page.push(grids);
						pageIds.push(gridIds);
						//reset back grid to repeat
						count = 0;
						grids = [];
						gridIds = [];
					}
					//push last remaining folders
					if (folderLength <= gridsPerPage && folderLength !== 0) {
						page.push(grids);
						pageIds.push(gridIds);
						folderLength = 0;
					}
				}
			});
		}

		if (gridView) {
			page.forEach((v, i) => {
				let obj = {},
					pageNo = i + 1;  //+1 to exclude start from '0'
				let pagesIds = pageIds[i],
					initPage = gridFolderIndex;
				if (pagesIds.includes(selectedFolder)) {
					if (contextChanged) {
						onSetGridFolderIndex(pageNo);
					}
					initPage = pageNo;
				}
				obj["page"] = i + 1;
				obj["view"] = (
					<Grids ids={pageIds[i]} className={"item-wrapper"}>
						{v}
					</Grids>
				);
				folderSets.push(obj);
			});
		}
		return folderSets;
	}, [
		currentContext,
		folders,
		review,
		all,
		my,
		vip,
		postponed,
		customFolders,
		gridView,
		gridFolderIndex,
		selectedFolder,
		contextChanged,
	]);

	useEffect(() => {
		switch (currentContext) {
			//all non-custom folders are in 1st page
			//so we redirect to 1st page on grid view (index 0)
			case MY_ERRANDS:
				if (gridFolderIndex == 0) {
					onSetGridFolderIndex(0);
				}
				break;
			case REVIEW_ERRANDS:
			case VIP_ERRANDS:
			case POSTPONED_ERRANDS:
			case NEW_ERRANDS:
				onSetGridFolderIndex(0);
				break;
			default:
				break;
		}
	}, [currentContext, gridFolderIndex]);

	const pages = useMemo(() => folderSets.map((set) => set.view.props.children), [folderSets]);
	const pagesId = useMemo(() => folderSets.map((set) => set.page), [folderSets]);

	return gridView ? (
		<FolderCarousel
			items={folderSets}
			setIndex={onSetGridFolderIndex}
			selectedPage={gridFolderIndex}
			page={pages}
			pageIds={pagesId}
		/>
	) : (
		<Fragment>
			{review}
			{postponed}
			{all}
			{my}
			{vip}
			{customFolders}
		</Fragment>
	);
};

const ErrandFolders = withUnmountWhenHidden(ErrandFoldersBase);

const UL = ({ children, className }) => <ul className={className}>{children}</ul>;

const withSearchCollapsedWrapper = Component => props => (
	<div className='search-collapsed'>
		<Component className="collapse-wfmenu" {...props} />
	</div>
);

const withErrandCollapsedWrapper = Component => ({ children }) => {
	const [show, collapseSideBar, onShow, onHide] = useShowAndHide();
	let collapseFolderListStyle = {
		top: features['hide-cention-logo'] ? "90px" : "133px"
	}
	return (
		<div
			className="collapse-folders active"
			id="collapse-folders"
			//TODO: investigate why below issue happens >.<
			//onMouseEnter={onShow}
			//onMouseLeave={onHide}
			onMouseEnter={onHide}
			onMouseLeave={onShow}
		>
			<div><i className="icon-errands" /></div>
			<div className="collapse-tooltip-folders" hidden={!show} style={collapseFolderListStyle}>
				<Component className="collapse-folders-list">
					<ErrandsItem active={show} />
					{children}
				</Component>
			</div>
		</div>
	);
};

const withWrapper = Component => props => (
	<Component className={props.className} {...props} />
);

const List = composeWithDisplayName(
	"List"
	, withUnmountWhenHidden
	, branch(
		({ collapseSideBar }) => collapseSideBar
		, branch(
			({ isSearch }) => isSearch
			, withSearchCollapsedWrapper
			, withErrandCollapsedWrapper
		)
		, withWrapper
	)
)(UL);

const Folders = ({
	chatErrandCount
	, collapseSideBar
	, currentContext
	, contextChanged
	, errandCount
	, folders
	, isSearch
	, myErrandFolder
	, onFolderClick
	, onReviewClick
	, onPostponeClick
	, onSetGridFolderIndex
	, gridFolderIndex
	, onSearchResult
	, onSearchFilter
	, realTimeCounter
	, reviewEnabled
	, searchList
	, selectedFolder
	, totalSearch
	, vipCount
	, postponeEnabled
	, vipTags
	, wfSettings
	, gridView
	, className
	, myQueueCount
	, newQueueCount
}) => {
	let allErrandsCount = 0
		, myErrandsCount = 0
		, searchAllErrand = 0
		, searchMyErrand = 0
		, searchCloseErrand = 0
		, reviewErrandsCount = 0
		, postponedErrandsCount = 0
		;
	if (realTimeCounter) {
		const { my: myCount, new: newCount, review: reviewCount, postponed: postponedCount } = errandCount;
		if (myCount) {
			myErrandsCount = myCount.total + chatErrandCount;
		}
		if (newCount) {
			allErrandsCount = newCount.total;
		}
		if (reviewCount) {
			reviewErrandsCount = reviewCount.total;
		}
		if (postponedCount) {
			postponedErrandsCount = postponedCount.total;
		}
	}
	if (IsContextSearch(currentContext)) {
		const { all, my, close } = searchList;
		searchAllErrand = all;
		searchMyErrand = my;
		searchCloseErrand = close;
	}
	const hide = gridView && !collapseSideBar

	return (
		<List className={className} hidden={hide} collapseSideBar={gridView ? false : collapseSideBar} isSearch={isSearch}>
			<SearchAndBackToBack
				backToSearch={<BackToSearch
					collapseSideBar={collapseSideBar}
					linkTo={`${process.env.PATH_PREFIX}v5/search`}
					text={I("Back to search")}
				/>}
				collapseSideBar={collapseSideBar}
				hidden={!isSearch}
				search={<SearchFolder
					collapseSideBar={collapseSideBar}
					current={currentContext}
					hidden={!totalSearch}
					count={totalSearch}
					onClick={onSearchResult}
				/>}
			/>
			<SearchFolders
				allCount={searchAllErrand}
				closedCount={searchCloseErrand}
				collapseSideBar={collapseSideBar}
				currentContext={currentContext}
				hidden={!isSearch}
				myCount={searchMyErrand}
				onClick={onSearchFilter}
			/>
			<ErrandFoldersWrap
				chatErrandCount={chatErrandCount}
				currentContext={currentContext}
				folders={folders}
				isSearch={isSearch}
				myErrandFolder={myErrandFolder}
				onFolderClick={onFolderClick}
				onReviewClick={onReviewClick}
				onPostponeClick={onPostponeClick}
				onSetGridFolderIndex={onSetGridFolderIndex}
				reviewEnabled={reviewEnabled}
				postponeEnabled={postponeEnabled}
				selectedFolder={selectedFolder}
				vipCount={vipCount}
				vipTags={vipTags}
				wfSettings={wfSettings}
				gridFolderIndex={gridFolderIndex}
				gridView={gridView}
				realTimeCounter={realTimeCounter}
				errandCount={errandCount}
				contextChanged={contextChanged}
				myQueueCount={myQueueCount}
				newQueueCount={newQueueCount}
			/>
		</List>
	);
}

export const ErrandFoldersWrap = ({
	chatErrandCount
	, collapseSideBar
	, currentContext
	, contextChanged
	, errandCount
	, folders
	, isSearch
	, myErrandFolder
	, onFilterByFolderClick
	, onFolderClick
	, onReviewClick
	, onSetGridFolderIndex
	, gridFolderIndex
	, realTimeCounter
	, reviewEnabled
	, postponeEnabled
	, onPostponeClick
	, selectedFolder
	, vipCount
	, vipTags
	, wfSettings
	, gridView
	, myQueueCount
	, newQueueCount
}) => {
	let allErrandsCount = 0
		, myErrandsCount = 0
		, reviewErrandsCount = 0
		, postponedErrandsCount = 0
		;
	if (realTimeCounter) {
		const { my: myCount, new: newCount, review: reviewCount, postpone: postponeCount } = errandCount;
		if (myCount) {
			myErrandsCount = myCount.total + chatErrandCount;
		}
		if (newCount) {
			allErrandsCount = newCount.total;
		}
		if (reviewCount) {
			reviewErrandsCount = reviewCount.total;
		}
		if (postponeCount) {
			postponedErrandsCount = postponeCount.total;
		}
	}

	const handleFolderClickBase = (modification) => {
		const params = { user: wfSettings.data.activeUserId }
			, context = modification(params)
			;
		onFolderClick(context, params, currentContext);
	}
	const handleFilterListByFolder = (folderId, folderName) => {
		return handleFolderClickBase(params => {
			if (folderId == 0) {
				params.source = NEW_ERRANDS;
				params.folder = 0;
				params.classification = "";
				params.isVIP = false;
				// TODO: why 'new context' no need set the agent inbox size???
				// and why each time switching context to 'my' we need to change
				// inbox size???
				return NEW_ERRANDS;
			} else if (folderName === VIP_ERRANDS) {
				params.source = NEW_ERRANDS;
				params.folder = 0;
				params.classification = vipTags;
				params.isVIP = true;
				return VIP_ERRANDS;
			} else if (folderName === CTX_POSTPONED) {
				params.source = CTX_POSTPONED;
				params.folder = 0;
				params.classification = "";
				params.isVIP = false;
				return CTX_POSTPONED;
			} else {
				params.folder = folderId;
				params.source = MY_ERRANDS;
				params.classification = "";
				params.size = wfSettings.data.agentInboxSize;
				params.isVIP = false;
				return MY_ERRANDS;
			}
		});
	}

	const withCount = features["hide.pottery.count"] ? false : true;
	return (
		<ErrandFolders
			all={<AllFolder
				hidden={wfSettings.data["hide-new-errands-menu"]}
				current={currentContext}
				count={allErrandsCount}
				withCount={withCount}
				onClick={handleFilterListByFolder}
				newQueueCount={newQueueCount}
			/>}
			currentContext={currentContext}
			folders={folders}
			hidden={isSearch}
			my={<MyFolder
				current={currentContext}
				count={myErrandsCount}
				withCount={withCount}
				id={myErrandFolder}
				onClick={handleFilterListByFolder}
				myQueueCount={myQueueCount}
			/>}
			onFilterByFolderClick={handleFilterListByFolder}
			review={<ReviewFolder
				hidden={!reviewEnabled}
				count={reviewErrandsCount}
				withCount={withCount}
				current={currentContext}
				onClick={onReviewClick}
			/>}
			selectedFolder={selectedFolder}
			vip={<VIPFolder
				id={VIP_ERRANDS}
				count={vipCount}
				withCount={withCount}
				hidden={vipCount > 0 ? false : true}
				current={currentContext}
				onClick={handleFilterListByFolder}
			/>}
			postponed={<PostponedFolder
				hidden={postponedErrandsCount > 0 ? false : true}
				count={postponedErrandsCount}
				withCount={withCount}
				current={currentContext}
				onClick={handleFilterListByFolder}
			//onClick={onPostponeClick}
			/>}
			contextChanged={contextChanged}
			gridView={gridView}
			onSetGridFolderIndex={onSetGridFolderIndex}
			gridFolderIndex={gridFolderIndex}
		/>
	);
}

const openErrandAttachedProps = props => {
	// NOTE: any extra fields added here must reflect on
	// openErrandExtraFieldsMemoize too.
	return {
		context: props.currentContext
		, mobile: props.ui.showMobileView
	};
};

function isErrandFullView({ currentContext, ui, currentOpened }) {
	return !IsContextSearch(currentContext)
		&& ui.errandListViewMode === PREF_LIST_VIEW
		&& currentOpened > 0;
}

let offsetC3Height = "50px";
let headerHeight = "0px";

const WorkflowInnerContainerBase = styled.div`
  height: ${props => `calc(${props.innerHeight} - (${headerHeight} + ${offsetC3Height} + ${handlingBarHeight}))`};
  overflow-y: auto;
`
export const WorkflowInnerContainer = (props) => {
	return <WorkflowInnerContainerBase ref={props.forwardRef} innerHeight={"100vh"} {...props} />;
};

/*export class WorkflowInnerContainer extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<WorkflowInnerContainerBase
				ref={this.props.forwardRef}
				innerHeight={this.props.windowInnerHeight}
				{...this.props}
			/>
		);
	}
}
*/

class WorkflowNew extends React.Component {
	constructor(props) {
		super(props);
		this.displayName = "WorkflowNew";
		this.state = {
			ready: false,
			showCollapsedNotif: false,
			myQueueCount: 0,
			newQueueCount: 0
		};
		this.handleBackToListView = this.handleBackToListView.bind(this);
		this.handleLoadNextPage = this.handleLoadNextPage.bind(this);
		this.handleSortTableList = this.handleSortTableList.bind(this);
		this.handleRefreshList = this.handleRefreshList.bind(this);
		this.selectedAreasHadDueDate = this.selectedAreasHadDueDate.bind(this);
		this.handleShowAdminMenuList = this.handleShowAdminMenuList.bind(this);
		this.handleHideAdminMenuList = this.handleHideAdminMenuList.bind(this);
		this.handleSetSelectedTags = this.handleSetSelectedTags.bind(this);
		this.handleMessageEvent = this.handleMessageEvent.bind(this);
		this.handleShowNotifResult = this.handleShowNotifResult.bind(this);
		this.handleHomePage = this.handleHomePage.bind(this);
	}
	calculateQueueCounts() {
		const cQ = this.props.ui.queuedErrands;
		cQ.forEach((item) => {
			if (item.qType === "agent") {
				if (item.direction === "in") {
					this.props.errandCount.my.total += 1;
				} else if (item.direction === "out") {
					this.props.errandCount.my.total -= 1;
				}
			} else if (item.qType === "area") {
				if (item.direction === "in") {
					this.props.errandCount.new.total += 1;
				} else
					if (item.direction === "out") {
						this.props.errandCount.new.total -= 1;
					}
			} else if (item.qType === "agent_postponed") {
				if (item.direction === "in") {
					this.props.errandCount.postpone.total += 1;
				} else if (item.direction === "out") {
					this.props.errandCount.postpone.total -= 1;
				}
			}
		});
		this.props.errandCount.my.total = Math.max(this.props.errandCount.my.total, 0);
		this.props.errandCount.new.total = Math.max(this.props.errandCount.new.total, 0);
		this.props.errandCount.postpone.total = Math.max(this.props.errandCount.postpone.total, 0);
	}

	componentDidMount() {
		this.props.onLoad(this.props)
			.then(() => {
				this.setState({ ready: true });
				this.calculateQueueCounts(); // Call the method here or elsewhere as needed
			});

		window.addEventListener('resize', this.handleWorkflowResize);
		window.addEventListener('message', this.handleMessageEvent);
	}

	componentDidUpdate(prevProps) {
		// Optionally call it in componentDidUpdate to recalculate when props change
		if (prevProps.ui.queuedErrands !== this.props.ui.queuedErrands) {
			this.calculateQueueCounts();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWorkflowResize);
		window.removeEventListener('message', this.handleMessageEvent);
		this.props.onUnload();
	}
	handleWorkflowResize = () => {
		if (isMobile) {
			this.props.onSetMobileView(true, window.innerHeight + "px");
		} else {
			this.props.onSetMobileView(false, window.innerHeight + "px");
			this.props.onSetActivateErrandMobileView(false);
		}
	}
	setDefaultAreaIds(areas) {
		var areaList = [];
		if (areas) {
			areas.forEach(function (d) {
				if (d.Areas != undefined) {
					d.Areas.forEach(function (a) {
						areaList.push(a.Id);
					}.bind(this));
				}
			}.bind(this));
		}
		return areaList;
	}
	handleSelectOneErrand = (id, select) => {
		this.props.onSelectErrandFromList(id, select);
	}
	handleSelectAllErrands = selectAll => {
		this.props.onSelectAllErrands(this.props.currentContext, selectAll);
	}
	handleShowAdminMenuList() {
		this.setState({ showCollapsedAdminMenu: true });
	}
	handleHideAdminMenuList() {
		this.setState({ showCollapsedAdminMenu: false });
	}
	searchErrandByFilter = (searchResults, activeUserId) => {
		let myErrand = 0, allErrand = 0, closeErrand = 0;
		if (searchResults.length > 0) {
			searchResults.forEach(el => {
				if (el.agentId === activeUserId && el.closedTime === "")
					myErrand++;
				else if (el.agentId === 0 && el.closedTime === "")
					allErrand++;
				else
					closeErrand++;
			});
		}
		return { myErrand, allErrand, closeErrand };
	}
	handleSearchFilterErrand = (id, value) => {
		if (this.props.currentContext == value) {
			return;
		}
		this.props.handleSearchList(value);
	}
	handleSearchResult = () => {
		if (this.props.currentContext == SEARCH_ERRANDS) {
			return;
		}
		this.props.handleSearchList(SEARCH_ERRANDS);
	}
	handleErrandClick = (id, isChat, cipherKey) => {
		this.props.onCloseErrandChatSidePanel(false);
		if (this.props.currentContext === CTX_REVIEW) {
			return this.props.onClickReview(id, cipherKey);
		}
		this.props.onSetCurrentErrand(
			id
			, isChat
			, openErrandAttachedProps(this.props)
			, this.props.isSearch
			, this.props.previousErrandId
		);
	}
	handleSideBarToggle = (e) => {
		this.props.onToggleSideBar(!this.props.ui.showSideBar);
	}
	handleSideBarCollapse = (e) => {
		this.props.onCollapseSideBar(!this.props.ui.collapseSideBar);
	}
	toggleErrandList = (e) => {
		this.props.onSetActivateErrandMobileView(false);
	}
	handleSortList = (val, index, dir, name) => {
		let params = {};
		this.props.onSelectErrandListSort(val, index);
		if (typeof dir !== 'undefined' && val == 'date') {
			params.sort_attribute = 'date';
			params.sort_direction = dir;
		}
		if (val == 'sort') {
			params.sort_direction = dir;
		} else {
			params.sort_attribute = val;
			params.sort_name = name;
		}

		this.props.onSetParamsThenReload(params);
	}
	handleLoadMore = () => {
		let nextOffsetSize = this.props.listInputs.size + this.props.wfSettings.data.agentInboxSize;
		this.props.onSetParamsThenReload({
			size: nextOffsetSize
		});
	}
	searchListPaginate = (offset) => {
		this.props.loadGlobalSearch(offset);
	}
	handleLoadNextPage(offset, size) {
		this.props.onSetParamsThenReload({
			offset: offset,
			size: size
		});
	}
	handleSortTableList(sortBy, order) {
		this.props.setLoadList();
		this.props.onSetParamsThenReload({
			sort_attribute: sortBy,
			sort_direction: order
		});
	}
	handleAutoBackToTop() {
		//Needs better implementation
		let scrollContainer = document.getElementById("ErrandList");
		if (scrollContainer) {
			scrollContainer.scrollTop = 0;
		}
	}
	handleBackToListView() {
		this.props.onBackToListView();
	}
	handleRefreshList() {
		this.props.onSetParamsThenReload({
			offset: 0
		});
	}
	selectedAreasHadDueDate() {
		let orgs = this.props.areas
		let retVal = true;
		let areaMap = new Map();
		if (this.props.selectedErrands == null ||
			this.props.selectedErrands.length == 0) {
			return retVal;
		}
		$.each(this.props.selectedErrands, (i, v) => {
			areaMap.set(v.area, v.area);
		});
		if (typeof orgs !== "undefined") {
			$.each(orgs, (i, v) => {
				if (v.Areas) {
					$.each(v.Areas, (j, area) => {
						if (areaMap.has(area.Id) &&
							area.DueDateSelected == false) {
							retVal = false;
							return;
						}
					});
				}
			});
		}
		return retVal;
	}
	handleSetSelectedTags(tags, add) {
		if (this.props.currentContext === VIP_ERRANDS) {
			if (!add) {
				let params = {};
				params.source = NEW_ERRANDS;
				params.folder = 0;
				params.classification = this.props.vipTags;
				this.props.onSetSelectedTags(tags);
				//little hack to check if it's clear all tags
				//need improvements
				if (this.props.filter.selectedTags.length === 1) {
					this.props.setParams(params);
				}
			} else {
				this.props.onSetSelectedTags(tags);
			}
		} else {
			this.props.onSetSelectedTags(tags);
		}
	}
	handleMessageEvent(e) {
		if (typeof e.data === "object") {
			if (typeof e.data.action !== 'undefined' && e.data.action !== null) {
				if (e.data.action == "open-errand") {
					let urlValid = isValidTelavoxUrl(e.origin);
					if (typeof e.data.params !== 'undefined' && e.data.params !==
						null && urlValid == true) {
						externalqueue.isPostMessage = true;
						if (typeof e.data.params.deepLink !== 'undefined' &&
							e.data.params.deepLink !== null &&
							e.data.params.deepLink !== "") {
							let encoded = e.data.params.deepLink;
							this.props.simpleLoadAndOpen(encoded,
								this.props.currentOpened);
						}
					}
				} else if (e.data.action == "subscribe-errands-count") {
					let params = {}
					params.errandsCount = 0;
					if (typeof this.props.errandCount !== 'undefined' &&
						this.props.errandCount != null &&
						this.props.errandCount.my !== null) {
						params.errandsCount = this.props.errandCount.my.count +
							this.props.chatErrandCount;
					}
					let apiparam = {};
					apiparam.action = "user-errands-count-changed";
					apiparam.params = params;
					e.source.postMessage(apiparam,
						(e.origin === "file://" ? "*" : e.origin));
					this.props.onSetPostMessageErrandCount({
						source: e.source,
						origin: e.origin
					});
				} else if (e.data.action == "create-errand") {
					// Create manual errand (voice API)
					this.handleCreateManualErrand(e);
				}
			}
		}
	}
	handleHomePage(e) {
		e.preventDefault();
		this.props.onMainView();
	}
	handleShowNotifResult() {
		this.setState({ showCollapsedNotif: !this.state.showCollapsedNotif });
	}
	handleCreateManualErrand(e) {
		if (typeof e.data === "object" &&
			typeof e.data.action !== 'undefined' && e.data.action !== null &&
			typeof e.data.params !== 'undefined' && e.data.params !== null &&
			this.isValidUrl(e.origin)) {
			let p = e.data.params;
			this.props.handleIntegrationCreateManualErrand({
				open_ui: p.open_ui,
				close_errand: p.close_errand,
				areaId: p.area_id,
				areaName: p.area_name,
				subject: p.subject,
				body: p.body,
				from: p.from,
			})
				.then(result => {
					if (e.data.params.open_ui && result.externalOpen) {
						this.props.simpleLoadAndOpen(result.externalOpen);
					}
				});
		}
	}
	render() {
		const {
			context
			, forwardErrandsAgents
			, isLoadingFwdErrandsAgents
			, onChangeAreaFilter
			, onOpenForwardToAgent
			, onSelectForwardToAgent
			, orgAreas
			, selectedAreas
			, selectedOrgs
			, ...p
		} = this.props
			, wf = p.wfSettings.data
			, ui = p.ui
			;
		if (!this.props.workflowReady) {
			return <SkeletonLoader
				view={"errand"}
				hide={false}
				collapseSideBar={ui.collapseSideBar}
				showSideBar={ui.showSideBar}
				showConversationList={p.ui.errandListViewMode === "Conversation"}
			/>
		}
		const {
			agentFilter
			, agents
			, areas
			, currentContext
			, filter
			, hasPendingReviewErrand
			, getCurrentAreas
			, onChangePriorityFilter
			, onReviewClick
			, onPostponeClick
			, onSetGridFolderIndex
			, gridFolderIndex
			, onSetFilteredAreas
			, onSetFilteredTags
			, onSetParamsThenReload
			, onSetSelectedAgent
			, onSetSelectedFolder
			, onShowAllArea
			, onToggleAgentsFilter
			, onToggleAreasListFilter
			, onTogglePriorityFilter
			, onToggleSideBar
			, onToggleTagsFilter
			, setLoadList
			, sort
			, tagsFilter
		} = p
			, setErrandFullView = isErrandFullView(p)
			, { selectedFolder } = filter
			;
		let errandListType = PREF_CONVERSATION_VIEW;
		if (!IsContextSearch(currentContext)) {
			errandListType = ui.errandListViewMode;
			if (!errandListType) {
				errandListType = PREF_CONVERSATION_VIEW;
			}
		}
		let layoutClass = "layout slim";
		let hide = true;
		let backNavHide = true;
		let errandVisible = false;
		if (setErrandFullView
			|| errandListType === PREF_CONVERSATION_VIEW
			|| ui.viewSingleErrandOnly) {
			errandVisible = true;
		}
		if (ui.showMobileView) {
			hide = true;
			if (ui.showSideBar) {
				hide = false;
				// layoutClass = "layout closed-sidebar";
			}
			if (ui.showErrandMobileView) {
				backNavHide = false;
				if (ui.showSideBar) {
					backNavHide = true;
				}
			}
		} else {
			hide = false;
			if (!ui.showSideBar) {
				layoutClass = "layout slim closed-sidebar";
			}
		}
		if (p.activateChat) {
			layoutClass += " opened-chat";
		}
		if (p.videoCallFullScreen) {
			layoutClass += " vc-fullscreen";
		}
		let listTotalCount = 0, previewFeature = false;
		if (currentContext !== SEARCH_ERRANDS) {
			if (p.errandList.data.totalErrandCount) {
				listTotalCount = p.errandList.data.totalErrandCount;
			}
		}

		let isExtQueue = false;
		let qType = getExtQueueType();
		if (qType != "" && qType != "undefined") {
			isExtQueue = true;
		}
		let isExternalQueueChat = (typeof externalqueue !== "undefined" &&
			externalqueue.isChat);
		let errandListChat;
		if (currentContext == MY_ERRANDS || isExternalQueueChat) {
			errandListChat = p.errandListChat;
			listTotalCount = listTotalCount + p.errandListChat.length;
		}

		let viewSingleErrandOnly = false;
		if (ui.viewSingleErrandOnly || p.isExternal || setErrandFullView) {
			viewSingleErrandOnly = ui.viewSingleErrandOnly || p.isExternal || setErrandFullView;
			layoutClass = "layout slim closed-sidebar";
		}
		if (isExternalQueueChat) {
			viewSingleErrandOnly = false;
		}

		let allowDueDate = this.selectedAreasHadDueDate(), sidebarClass = "";
		if (ui.collapseSideBar) {
			sidebarClass = "collapsed";
		}
		let showMainMenuNav = false;
		if (typeof currentContext !== "undefined") {
			showMainMenuNav = true;
		}
		let makeLogoClickable = true;
		if (qType.length > 0 || viewSingleErrandOnly) {
			makeLogoClickable = false;
		}
		let logoWrapperStyle = {}, flexMenusStyle = {};
		if (ui.collapseSideBar) {
			logoWrapperStyle = { margin: "auto", textAlign: "center" }
			flexMenusStyle = { padding: "0px" };
		}
		let notifMsgLen = p.notification.uiData.noticeCount;
		const hideLogo = features['hide-cention-logo'];
		let customStyledAppHeader = { borderRadius: "initial" }
		if (hideLogo && ui.collapseSideBar) {
			customStyledAppHeader = { borderRadius: "initial", padding: "0px 15px" }
		}
		const hideCounter = features['hide.pottery.count'];
		const FolderContainer = (p) => {
			return (
				<Folders
					chatErrandCount={this.props.chatErrandCount}
					collapseSideBar={ui.collapseSideBar}
					currentContext={currentContext}
					errandCount={this.props.errandCount}
					vipCount={this.props.vipTagsErrandsCount}
					folders={wf.userFolders}
					isSearch={this.props.isSearch}
					myErrandFolder={this.props.myErrandFolder}
					onFolderClick={this.props.onFolderClick}
					onReviewClick={onReviewClick}
					onPostponeClick={onPostponeClick}
					onSearchResult={this.handleSearchResult}
					onSearchFilter={this.handleSearchFilterErrand}
					realTimeCounter={this.props.realTimeCounter}
					reviewEnabled={this.props.reviewFolderEnabled}
					postponeEnabled={this.props.postponeFolderEnabled}
					searchList={this.props.searchList}
					selectedFolder={this.props.filter.selectedFolder}
					contextChanged={this.props.filter.contextChanged}
					totalSearch={this.props.totalSearch}
					wfSettings={this.props.wfSettings}
					onSetGridFolderIndex={onSetGridFolderIndex}
					gridFolderIndex={gridFolderIndex}
					vipTags={this.props.vipTags}
					myQueueCount={this.state.myQueueCount}
					newQueueCount={this.state.newQueueCount}
					{...p}
				/>
			)
		}
		return (
			<div className="errand-page">
				<div id="page-wrapper">
					<section className={layoutClass} data-app-layout>
						{(ui.showMobileView ? true : ui.showSideBar) &&
							!isExtQueue &&
							<div className={classNames("app-sidebar", sidebarClass)}>
								<div className="app-header" style={customStyledAppHeader} >
									{(hideLogo) &&
										<span className="app-back-nav" hidden={backNavHide} onClick={this.toggleErrandList}>
											<i className="icon-chevron-left"></i>
										</span>
									}
									{(hideLogo) &&
										!ui.collapseSideBar &&
										<MenuCtnr visible={!ui.showErrandMobileView} pageContext={currentContext} />
									}
									{(hideLogo) &&
										<ToggleSideBar visible={ui.showSideBar || ui.showMobileView}
											showSideBar={ui.showSideBar}
											collapsed={ui.collapseSideBar}
											mobileMode={ui.showMobileView}
											id={"QA_toggle_sidebar_top"}
											onCollapse={this.handleSideBarCollapse}
											onClick={this.handleSideBarToggle} />
									}
									<div className="side-logo" style={logoWrapperStyle} hidden={hideLogo}>
										{
											makeLogoClickable ?
												<CancellableSimpleLink onClick={this.handleHomePage} title={I("Back to Main")}>
													<img src={ui.collapseSideBar ? CENTION_LOGO_MINI : CENTION_LOGO} alt="" />
												</CancellableSimpleLink> :
												<div>
													<img src={ui.collapseSideBar ? CENTION_LOGO_MINI : CENTION_LOGO} alt="" />
												</div>
										}
									</div>
								</div>
								<WorkflowInnerContainerCtnr className="app-inner-content" data-custom-scrollbar hidden={hide}>
									<div className="flex-menus-wrap" style={flexMenusStyle}>
										<div className="side-bar-main-menu" hidden={hideLogo}>
											<span className="app-back-nav" hidden={backNavHide} onClick={this.toggleErrandList}>
												<i className="icon-chevron-left"></i>
											</span>
											{
												!ui.collapseSideBar &&
												<MenuCtnr visible={!ui.showErrandMobileView} pageContext={currentContext} />
											}
											<ToggleSideBar
												visible={ui.showSideBar || ui.showMobileView}
												showSideBar={ui.showSideBar}
												collapsed={ui.collapseSideBar}
												mobileMode={ui.showMobileView}
												id={"QA_toggle_sidebar_top"}
												onCollapse={this.handleSideBarCollapse}
												onClick={this.handleSideBarToggle}
											/>
										</div>
										{ui.collapseSideBar &&
											<div id="side-notif-menu" key={"collapse-side-menu"} className={classNames(
												"collapse-menu"
												, { "show-panel": this.state.showCollapsedNotif }
											)}>
												{
													notifMsgLen > 0 ?
														<a data-notices-count={notifMsgLen} data-qa-id="header-notif" data-dropdown-target="notices-dropdown" className="with-notices"><i className="icon-bell"></i></a>
														: <i className="icon-bell" title={I("No notifications")}></i>
												}
												<SideBarDropdownWrapperWithTooltip show={this.state.showCollapsedNotif && notifMsgLen > 0} className="collapse-tooltip-menu notif-side-menu-collapse" autohide={false} onToggle={this.handleShowNotifResult}
													target={"side-notif-menu"} >
													<li data-tooltip data-qa-id="notif_side_menu">
														<AgentNotification
															messages={p.notification.messages}
															onToggleArrow={p.onToggleArrow}
															onSetNotificationTab={p.onSetNotificationTab}
															openAnnouncement={p.openAnnouncement}
															onShowAllNotification={p.onShowAllNotification}
															noticeCount={notifMsgLen}
															uiData={p.uiData}
															agentTimezoneOffset={p.agentTimezoneOffset}
															openLastErrandInThread={p.OpenLastErrandInThread}
															openCollaReplyPanel={p.openCollaReplyPanel}
															openErrand={p.openErrand}
															openMessage={p.openMessage}
															openAccounts={p.openAccounts}
															openStatistics={p.openStatistics}
															forSidebar={true}
														/>
													</li>
												</SideBarDropdownWrapperWithTooltip>
											</div>
										}
										<FolderContainer className={"sidebar-menu"} />
										{ui.collapseSideBar && showMainMenuNav && !IsContextSearch(currentContext) &&
											<IconizeSideBarMenuCtnr
												currentPage={MM_WORKFLOW}
												onChangePage={this.props.onChangeAdminView}
												uiData={this.props.uiData}
												agentTimezoneOffset={this.props.agentTimezoneOffset}
												menu={this.props.menu}
												agent={this.props.agent}
												newIMCount={this.props.newIMCount}
												onToggleArrow={this.props.onToggleArrow}
												notification={this.props.notification}
												onShowAllNotification={this.props.onShowAllNotification}
												OpenLastErrandInThread={this.props.OpenLastErrandInThread}
												openCollaReplyPanel={this.props.openCollaReplyPanel}
												openErrand={this.props.openErrand}
												openMessage={this.props.openMessage}
												openStatistics={this.props.openStatistics}
												openAccounts={this.props.openAccounts}
												isSearching={this.props.isSearching}
												searchResults={this.props.searchResults}
												searchText={this.props.searchText}
												showResultBox={this.props.showResultBox}
												handleKeyDownSearchBox={this.props.handleKeyDownSearchBox}
												handleKeyUpSearchBox={this.props.handleKeyUpSearchBox}
												handleChangeSearchBox={this.props.handleChangeSearchBox}
												handleSearchButton={this.props.handleSearchButton}
												handleHeaderSearchDD={this.props.handleHeaderSearchDD}
												disableActiveSearch={this.props.disableActiveSearch}
											/>
										}
										<Filters
											agentAreas={areas}
											agentFilter={agentFilter}
											agentInboxSize={wf.agentInboxSize}
											agents={agents}
											autoBackToTop={this.handleAutoBackToTop}
											areaFilter={p.areaFilter}
											context={context}
											currentContext={currentContext}
											filter={p.filter}
											getCurrentAreas={getCurrentAreas}
											isGrpLeader={wf["group-leader.personal-errands"]}
											hidden={ui.collapseSideBar}
											onChangeAreaFilter={onChangeAreaFilter}
											onChangePriorityFilter={onChangePriorityFilter}
											onSetFilteredAreas={onSetFilteredAreas}
											onSetFilteredTags={onSetFilteredTags}
											onSetSelectedAgent={onSetSelectedAgent}
											onSetSelectedFolder={onSetSelectedFolder}
											onSetSelectedTags={this.handleSetSelectedTags}
											onShowAllArea={onShowAllArea}
											onSetParamsThenReload={onSetParamsThenReload}
											onToggleAgentsFilter={onToggleAgentsFilter}
											onToggleAreasListFilter={onToggleAreasListFilter}
											onTogglePriorityFilter={onTogglePriorityFilter}
											onToggleSideBar={onToggleSideBar}
											onToggleTagsFilter={onToggleTagsFilter}
											orgAreas={orgAreas}
											selectedAreas={selectedAreas}
											selectedOrgs={selectedOrgs}
											setLoadList={setLoadList}
											sort={sort}
											tagsFilter={tagsFilter}
											ui={ui}
											hideCounter={hideCounter}
										/>
									</div>
									<SidebarSettingsCtnr />
								</WorkflowInnerContainerCtnr>
							</div>
						}
						{
							(!setErrandFullView && !viewSingleErrandOnly) &&
							<ErrandListCtnr
								allowAgentForwardToAgent={wf.agentForwardToAgent}
								allowForwardErrandsToAgent={wf.forwardErrandsToAgent}
								forwardErrandsAgents={forwardErrandsAgents}
								isLoadingFwdErrandsAgents={isLoadingFwdErrandsAgents}
								type={errandListType}
								data={p.errandList}
								listViewData={p.listViewData}
								searchData={p.searchList.list}
								searchResults={p.searchResults}
								totalSearchResult={p.totalSearchFiltered}
								searchOffset={p.searchOffset}
								canOffCollaborationLight={p.canOffCollaborationLight}
								currentOpened={p.currentOpened}
								errandListChat={errandListChat}
								errandAllSelected={p.errandAllSelected}
								chatErrandSelected={p.chatErrandSelected}
								hasDueDateSelected={p.hasDueDateSelected}
								hasPendingReviewErrand={hasPendingReviewErrand}
								allowDueDate={allowDueDate}
								loading={p.loadingErrandList}
								listCurrentSorting={p.listInputs.sort_attribute}
								listCurrentSortName={p.listInputs.sort_name}
								listSortDirection={p.listInputs.sort_direction}
								listTotalCount={listTotalCount}
								listTotalCountForPagination={p.errandList.data.totalErrandCount}
								errandCount={p.errandCount}
								vipCount={p.vipTagsErrandsCount}
								totalSelected={p.totalSelected}
								selectedErrands={p.selectedErrands}
								currentSize={p.listInputs.size}
								currentOffset={p.listInputs.offset}
								listReady={ui.listReady}
								onLoadMore={this.handleLoadMore}
								onNextPage={this.handleLoadNextPage}
								onOpenForwardToAgent={onOpenForwardToAgent}
								onSelectForwardToAgent={onSelectForwardToAgent}
								onSortList={this.handleSortTableList}
								onSetLoadList={this.handleRefreshList}
								onSetParam={p.setParams}
								onToggleLight={p.onToggleLight}
								onClick={this.handleErrandClick}
								showSort={ui.showErrandListSort}
								showSideBar={ui.showSideBar}
								collapseSideBar={ui.collapseSideBar}
								mobileMode={ui.showMobileView}
								showErrandMobileView={ui.showErrandMobileView}
								agentCanPutBackErrands={wf.agentCanPutBackErrands}
								showListView={errandListType}
								showErrandFullView={setErrandFullView}
								selectedSort={ui.errandListSort}
								tzOffset={wf.agentTimezoneOffset}
								onToggleShowSort={p.onToggleErrandListSort}
								onSelectSort={this.handleSortList}
								onSelectOne={this.handleSelectOneErrand}
								onToggleSideBar={this.handleSideBarToggle}
								onSelectAll={this.handleSelectAllErrands}
								previewActivated={p.previewActivated}
								onLoadPreview={p.onLoadPreview}
								previewData={p.errandPreview}
								userFolders={p.wfSettings.data.userFolders}
								onSetErrandView={p.onSetErrandView}
								showMoveToFolder={ui.showMoveToFolder}
								onHandleMoveToFolder={p.onHandleMoveToFolder}
								agentCanMoveFromInboxFeature={p.wfSettings.data["folders.move-from-new-errands"]}
								agentCanForwardToAreaFeature={!p.wfSettings.data["agent.turn-off-forward-to-area-from-inbox"]}
								agentCanForwardToAreaInOrganizationFeature={p.wfSettings.data["agent.only-forward-to-area-in-organisation"]}
								forwardToAreas={p.wfSettings.data.forwardToAreas}
								showForwardAreaOptions={ui.showForwardAreaOptions}
								onHandleForwardToArea={p.onHandleForwardToArea}
								onHandlePrintErrands={p.onHandlePrintErrands}
								shouldPopPrint={p.shouldPopPrint}
								printContent={p.printContent}
								currentContext={currentContext}
								contextChanged={p.filter.contextChanged}
								agentCanDeleteErrandsFeature={p.wfSettings.data["agent-delete-errands.admin-edit"]}
								onHandleDelete={p.onDeleteErrands}
								onHandleClose={p.onCloseErrands}
								onHandleSetPriority={p.onHandleSetPriority}
								onHandleSetDueDate={p.onHandleSetDueDate}
								onHandleReturnToInbox={p.onHandleReturnToInbox}
								onHandleSendingBulkErrand={p.onHandleSendingBulkErrand}
								onHandleLinkErrand={p.onHandleLinkErrand}
								onHandleMultipleErrandAction={p.onHandleMultipleErrandAction}
								onGetCollaborationInfo={p.onGetCollaborationInfo}
								collaborationInfo={p.collaborationInfo}
								showOrganizationColumnFeature={p.wfSettings.data.showErrandOrganisationColumn}
								dueDateFeature={p.wfSettings.data.doneDateFeature}
								postponeDateFeature={p.wfSettings.data.postponeDoneDate}
								showHighPriorityFeature={p.wfSettings.data.highPriority}
								showMultipleActions={ui.showMultipleActions}
								viewSingleErrandOnly={viewSingleErrandOnly}
								isExternal={p.isExternal}
								clientAvatar={p.clientAvatar}
								onSearchListPaginate={this.searchListPaginate}
								showExactDayAndTime={p.wfSettings.data["show-exact-day-and-time"]}
								allowCloseErrand={p.wfSettings.data["close-errand-without-answering"]}
								defaultFolderId={p.defaultFolderId}
								onGetSlaTime={this.props.onGetSlaTime}
								slaTime={this.props.slaTime}
								showSlaTimeFeature={p.wfSettings.data.showSlaTime}
								selectedAgent={p.filter.selectedAgent}
								areas={p.wfSettings.data.areas}
								agentWorking={p.agentWorking}
								handleAcquireFromOtherAgentErrand={p.handleAcquireFromOtherAgentErrand}
								activeCreatedErrand={p.activeCreatedErrand}
								sipGetCurrentEid={p.sipGetCurrentEid}
								sipMakeCallCurrentErrand={p.sipMakeCallCurrentErrand}
								onFetchQueueErrands={p.onFetchQueueErrands}
								folderUI={<FolderContainer className="grid-menu" gridView={true} />}
							/>
						}
						{errandVisible &&
							<ErrandCtnr
								allowAgentForwardToAgent={wf.agentForwardToAgent}
								allowForwardErrandsToAgent={wf.forwardErrandsToAgent}
								currentContext={currentContext}
								currentOpened={p.currentOpened}
								forwardErrandsAgents={forwardErrandsAgents}
								hasPendingReviewErrand={hasPendingReviewErrand}
								isLoadingFwdErrandsAgents={isLoadingFwdErrandsAgents}
								listType={errandListType}
								onOpenForwardToAgent={onOpenForwardToAgent}
								onSelectForwardToAgent={onSelectForwardToAgent}
								visible={errandVisible}
								listTotalCount={listTotalCount}
								totalSelected={p.totalSelected}
								selectedErrands={p.selectedErrands}
								onSetLoadList={this.handleRefreshList}
								userFolders={p.wfSettings.data.userFolders}
								onSetErrandView={p.onSetErrandView}
								showMoveToFolder={ui.showMoveToFolder}
								onHandleMoveToFolder={p.onHandleMoveToFolder}
								agentCanMoveFromInboxFeature={p.wfSettings.data["folders.move-from-new-errands"]}
								agentCanForwardToAreaFeature={!p.wfSettings.data["agent.turn-off-forward-to-area-from-inbox"]}
								agentCanForwardToAreaInOrganizationFeature={p.wfSettings.data["agent.only-forward-to-area-in-organisation"]}
								agentCanDeleteErrandsFeature={p.wfSettings.data["agent-delete-errands.admin-edit"]}
								showHighPriorityFeature={p.wfSettings.data.highPriority}
								showForwardAreaOptions={ui.showForwardAreaOptions}
								sideBarShown={ui.showSideBar}
								forwardToAreas={p.wfSettings.data.forwardToAreas}
								onHandleForwardToArea={p.onHandleForwardToArea}
								onHandlePrintErrands={p.onHandlePrintErrands}
								shouldPopPrint={p.shouldPopPrint}
								printContent={p.printContent}
								onHandleDelete={p.onDeleteErrands}
								onHandleClose={p.onCloseErrands}
								onHandleSetPriority={p.onHandleSetPriority}
								onHandleSetDueDate={p.onHandleSetDueDate}
								onHandleReturnToInbox={p.onHandleReturnToInbox}
								onHandleLinkErrand={p.onHandleLinkErrand}
								onHandleMultipleErrandAction={p.onHandleMultipleErrandAction}
								fullView={setErrandFullView}
								handleBackToList={this.handleBackToListView}
								errandViewOnly={p.errandViewOnly}
								isExternal={p.isExternal}
								allowDueDate={allowDueDate}
								name={wf.activeUserName}
								hasClosedErrand={p.hasClosedErrand}
								myErrandsOnly={p.myErrandsOnly}
								mobileView={ui.showMobileView}
								selectedAgent={p.filter.selectedAgent}
							/>
						}
					</section>
				</div>
			</div>
		);
	}
};

const useReviewEffect = ({
	onExitFromReview
	, onOpenReviewErrand
	, onOpenReviewFolder
	, review
	, ...props
}) => {
	const lastestReview = useRef();
	useEffect(
		() => {
			if (review) {
				if (process.env.NODE_ENV !== 'production') {
					console.log("dbg how frequent this render");
				}
				const { cipherKey, id } = review.params;
				if (typeof id === "undefined") {
					onOpenReviewFolder();
				} else {
					onOpenReviewErrand(id, cipherKey);
				}
			} else if (lastestReview.current) {
				onExitFromReview();
			}
			lastestReview.current = review;
		}
		, [onExitFromReview, onOpenReviewErrand, onOpenReviewFolder, review]
	);
};

const WorkflowBase = props => {
	useReviewEffect(props);
	return <WorkflowNew {...props} />;
};

export default WorkflowBase;
